* {
  box-sizing: border-box;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

button {
  font-family: inherit;
}
